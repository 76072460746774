
.App {
  width: 500px !important;
  min-height: 100vh  !important;
  background-color: #0f0638;
  margin: auto;
  box-shadow: 0 0 1em #cccccc;
  //overflow-y: auto;
}

::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    background-color: #F5F5F555;
  }

  ::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F544;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(129, 111, 111, 0.2);
    background-color: #555;
  }



@media (max-width: 500px) {
  .App {
      width: 100vw !important;
      height: 100%  !important;
      background-color: #0f0638;
      margin: auto;
      //box-shadow: 0 0 1em #cccccc;
      //overflow-y: auto;
  }


  ::-webkit-scrollbar-track
  {
    width: 0px;
  }

  ::-webkit-scrollbar
  {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb
  {
    width: 0px;
  }
}
