input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    //display: none;
    //-webkit-appearance: none;
    filter: invert(1);
}


.hash {
    margin-top: 50px;
    width: 100% !inportant;
    overflow-wrap: break-word;
    font-size:18px;
}