.panel__navigation {
    //height: calc(100vh - 140px) !important;
}


.main-nav span {
    font-size: 20px !important;
}

.subnav-header,
.main-nav li {
    cursor: pointer;
}



.user-details__title span {
    font-size: 22px !important;
    padding: 5px;
}