.Percent .account-text {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}

.Percent .account-text div {
    width: 240px !important;
}


.Percent .round-img {
    border-radius: 25%;
    height:154px;
    width:154px;
    overflow: hidden;  
}

.Percent .round-img img {
    height:100%;
    width:auto;
    margin-left: 50%;
    transform: translateX(-50%);
}


.Percent .caption__content {
    height: 135px;
    display: grid;
    grid-template-columns: 100px auto;
}


.Percent .platform {
    display: flex;
    height: 135px;
    align-items: center;
    flex-wrap: wrap;
}


.Percent .platform_text {
    width: 100%;
}


.Percent .platform_title {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    width: 100%;
    height: auto;
}

.Percent .platform_subtitle {
    font-size: 14px;
    text-align: center;
    width: 100%;
}


.Percent .btn-invite {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Percent .btn-invite a {
    margin-top:5px;
    width: 110px;
    height: 30px;
    padding: 0px !important;
    color: #000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Percent .plat_img {
    margin-left:5px;
    width:90px;
    height:90px;
    border-radius: 20%;
    
}


.Percent .card-coin {
    /*height: 50px;
    display: grid;
    grid-template-columns: 50px 110px auto auto auto;
    column-gap: 5px !important;*/
}

.Percent .card-game {
    padding: 5px 5px 15px 5px;
}

.Percent .game_icon {
    width:auto;
    height:100px;
    border-radius: 8%;
}


.Percent .game_title {
    margin-left:6px;
    font-size: 14px;
    width: 100px !important;
    text-transform: uppercase;
}


.Percent .game {
    flex-wrap: wrap;
}


.Percent .game_content {
    
}


.Percent .game_bar {
    width: 100% !important;
    height: 30px;
    border-radius: 15px;
    background: linear-gradient(180deg, #291F57 0%, #291F57 100%) !important;
}


.Percent .game_bar_fill {
    position:relative;
    //width: 60% !important;
    height: 26px;
    border-radius: 15px;
    //background-color: #ff0000AA;
    border: 2px #000000FF solid;

    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}




.Percent .game_red {
    background: linear-gradient(180deg, #ff0000AA 0%, #ff000044 100%)  !important;
}

.Percent .game_yellow {
    background: linear-gradient(180deg, #FF8000EE 0%, #FF800044 100%)  !important;
}

.Percent .game_green {
    background: linear-gradient(180deg, #00B22DEE 0%, #00B22D44 100%)  !important;
}


.Percent .game_bet {
    padding: 3px;
    font-size: 11px;
    text-align: center;
}





.unauthorized {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
}


.maintenace {
    margin-top: 35%;
    height: calc(100vh - 140px);
}


.unauthorized img {
    width: 90%;
    max-width: 380px;
    height:  auto;
}





@keyframes blink2 {
    0% {
        background-color: #C2621366;
    }
    50% {
        background-color: #A8091C66;
    }
    100% {
        background-color: #059A3166;
    }
}
