#Footer {
    position: fixed;
    width: 500px;
    bottom: 0;
    z-index: 9999;
}


@media (max-width: 500px) {
    #Footer {
        width: 100vw !important;
    }
}