.user_camera {
    position: relative;
    margin-top: -50px !important;
    margin-left: 125px !important;
}

.img-thumb {
    display:flex;
    justify-content: center;
}

.pointer {
    cursor: pointer;
}

.form_spacing {
    padding: 15px;
}

#presentation_text {
    height: 150px;
    resize: none;
}