.account-text {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}

.account-text div {
    width: 240px !important;
}


.round-img {
    border-radius: 25%;
    height:154px;
    width:154px;
    overflow: hidden;  
}

.round-img img {
    height:100%;
    width:auto;
    margin-left: 50%;
    transform: translateX(-50%);
}


.caption__content {
    height: 135px;
    display: grid;
    grid-template-columns: 100px auto;
}


.platform {
    display: flex;
    height: 135px;
    align-items: center;
    flex-wrap: wrap;
}


.platform_text {
    width: 100%;
}


.platform_title {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    width: 100%;
    height: auto;
}

.platform_subtitle {
    font-size: 14px;
    text-align: center;
    width: 100%;
}


.btn-invite {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-invite a {
    margin-top:5px;
    width: 110px;
    height: 30px;
    padding: 0px !important;
    color: #000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.plat_img {
    margin-left:5px;
    width:90px;
    height:90px;
    border-radius: 20%;
    
}


.card-coin {
    height: 50px;
    display: grid;
    grid-template-columns: 50px 110px auto auto auto;
    column-gap: 5px !important;
}




.game_title {
    margin-left:6px;
    font-size: 14px;
    width: 100px !important;
    text-transform: uppercase;
}


.game {
    flex-wrap: wrap;
}


.game_content {
    margin-top: -7px;
}


.game_icon {
    margin-left:-2px;
    margin-top:-2px;
    width:58px;
    height:auto;
}

.game_image {
    width:52px;
    height:52px;
    overflow: hidden;
    border-radius: 25%;
}

.search__form {
    width:50%;   
}

.page__title-right {
    margin-top: -15px;
}


.game_bar {
    width: 100% !important;
    height: 24px;
    border-radius: 25px;
    background: linear-gradient(180deg, #231B4B 0%, #0F0638 100%) !important;
}


.game_bar_fill {
    position:relative;
    //width: 60% !important;
    height: 18px;
    border-radius: 25px;
    //background-color: #ff0000AA;
    border: 2px #000000 solid;
    text-align: center;
    font-weight: bold;
}




.game_red {
    background: linear-gradient(180deg, #ff0000AA 0%, #ff000044 100%)  !important;
}

.game_yellow {
    background: linear-gradient(180deg, #FF8000EE 0%, #FF800044 100%)  !important;
}

.game_green {
    background: linear-gradient(180deg, #00B22DEE 0%, #00B22D44 100%)  !important;
}


.game_bet {
    padding: 3px;
    font-size: 11px;
    text-align: center;
}





.unauthorized {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
}


.maintenace {
    margin-top: 35%;
    height: calc(100vh - 140px);
}


.unauthorized img {
    width: 90%;
    max-width: 380px;
    height:  auto;
}





@keyframes blink {
    0% {
        background-color: #C2621366;
    }
    50% {
        background-color: #A8091C66;
    }
    100% {
        background-color: #059A3166;
    }
}
