.plat-form_spacing {
    padding-left: 5px;
    padding-right: 5px;
}


.center_thing {
    //float: left;
}

.center_thing img {
    height: 22px;
    padding-right: 20px;
}


.platform_thumb {
    width: 50px;
    height: 50px;
}


.card__icon {
    width: 50px !important;
    height: 50px !important;
    max-width: 50px !important;
}

.card__icon img {
    width: 50px !important;
    height: 50px !important;
    max-width: 50px !important;
}

#tipo {
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
    background-color: #291f57;
}